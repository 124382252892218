import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { GameById, GameService, LikedGames } from '../app/service/game.service';
import { GAME_URL } from '../config/constant';
import { GameLeaderBoradDetails } from '../app/service/gamestats.service';
import { LeaderBoard } from '../components/leader-board';
import { ChallengeService } from '../app/service/challenge.service';
import { ChallengeInfo } from '../app/model/dto/res/challenge-info';
import { connect } from 'react-redux';
import { PlatformActionType, PopupType, UserActionType } from '../app/redux/reducers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose, faHeart, faStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { Resources } from '../app/utility/resources';
import { Wallet } from '../app/model/dto/res/wallet';
import { MrPopup } from '../app/components/mr-popup';
import { UserService } from '../app/service/user.service';
import { RateApp } from '../app/components/rate-app';
import { Game } from '../app/model/dto/res/game';
import { User } from '../app/model/dto/res/user';
import AdBanner from '../components/ad-banner';

interface Props {
  clearMultiplayer?: () => void;
  updateWallet: (coins: number) => void;
  wallet: Wallet;
  updateScrolledGame: (gameId: string) => void;
  User: User;
  showUserLogin?: () => void;
}
const GameInfo = (props: Props) => {
  let { id } = useParams<{ id: string }>();
  const gameId = id as string;
  const url = new URL(window.location.href);
  const challengeId = url.searchParams.get('challengeId');
  let screenFrom = url.searchParams.get('_sF') || 'home-page';
  const navigation = useNavigate();
  const [gameData, setGameData] = useState<Game>();
  const [challengedInfoData, setChallengedInfoData] = useState<ChallengeInfo>();
  const [leaderBoardData, setLeaderBoardData] = useState<any>([]);
  const [showPromoGameVideo, setShowPromoGameVideo] = useState<boolean>(false)
  const promoVideoRef: any = useRef<HTMLDivElement>(null);
  const [acceptChallengeErr, setAcceptChallengeErr] = useState('');
  const [showCoinsPopup, setShowCoinsPopup] = useState(false);
  const [canShowChallengeConfirmation, setCanShowChallengeConfirmation] = useState(false);
  const [canShowRateApp, setCanShowRateApp] = useState(false);
  const [is_liked, setIs_liked] = useState(false);
  const [isSinglePlayerAvailable, setIsSinglePlayerAvailable] = useState(false);
  const [isMultiplayerAvailable, setIsMultiplayerAvailable] = useState(false);

  useEffect(() => {
    gameDetailsById(gameId);
    leaderBoard(gameId);
    if (challengeId) {
      loadChallenges(challengeId);
    }
    if (props.updateScrolledGame) {
      props.updateScrolledGame(gameId);
    }
  }, [])

  useEffect(() => {
    if (gameData) {
      (window as any).gtag('event', 'view_game_details', {
        'game_name': gameData.name
      });
      if (gameData.game_modes && gameData.game_modes.length > 0) {
        if (gameData.game_modes.find(el => el === 1)) {
          setIsSinglePlayerAvailable(true);
        }
        if (gameData.game_modes.find(el => el === 2)) {
          setIsMultiplayerAvailable(true);
        }
      } else {
        setIsSinglePlayerAvailable(true);
        setIsMultiplayerAvailable(false);
      }
    }


  }, [gameData]);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        promoVideoRef.current &&
        !promoVideoRef.current.contains(event.target as Node)
      ) {
        closePromoVideoPopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPromoGameVideo]);

  const closePromoVideoPopup = () => {
    setShowPromoGameVideo(false);
  };

  const loadChallenges = async (data: string) => {
    const recs = await ChallengeService.getChallengeInfo(data);
    if (recs.length > 0) {
      setChallengedInfoData(recs[0]);
    }
  }
  const gameDetailsById = (data: string) => {
    GameById(data).then((res: Game) => {
      setGameData(res)
      setIs_liked(res.is_liked);
      window.scrollTo({ top: 0 });
    })
  }

  const leaderBoard = (data: any) => {
    const gameId = data;
    GameLeaderBoradDetails(gameId).then((res: any) => {
      setLeaderBoardData(res)
    })
  }

  const showGamePromoVideo = () => {
    setShowPromoGameVideo(true);
  }

  const onAcceptChallenge = async () => {
    try {
      if (props.clearMultiplayer) {
        props.clearMultiplayer();
      }
      await ChallengeService.acceptChallenge(challengeId as string);
      navigation(`/play/${gameId}${challengeId ? `?challengeId=${challengeId}` : ``}`);
    } catch (error: any) {
      setAcceptChallengeErr(error.msg);
    }

  }

  const onOpenSinglePlayer = async (type: 0 | 1) => {
    if (props.clearMultiplayer) {
      props.clearMultiplayer();
    }
    // if (props.wallet && props.wallet.coins >= 5) {
    //   if (type === 0) {
    //     if (props.updateWallet) {
    //       props.updateWallet(5);
    //       await UserService.playGame(gameId, 1);
    //       setShowCoinsPopup(false);
    //       navigation(`/play/${gameId}`);
    //     }
    //   } else {
    //     navigation(`/play/${gameId}?type=${type}`);
    //   }
    // } else {
    //   setShowCoinsPopup(true);
    // }
    navigation(`/play/${gameId}?type=${type}`);
  }

  const onOpenMultiplayer = async () => {
    // if (props.wallet && props.wallet.coins >= 50) {
    //   if (props.updateWallet) {
    //     props.updateWallet(50);
    //     if (props.clearMultiplayer) {
    //       props.clearMultiplayer();
    //       await UserService.playGame(gameId, 2);
    //       navigation(`/waiting/${gameId}`);
    //     }
    //   }
    // } else {
    //   setShowCoinsPopup(true);
    // }
    navigation(`/waiting/${gameId}`);
  }

  const likeGame = async () => {
    const data: any = {
      game_id: gameData?._id,
      user_id: props?.User._id,
      type: 'like'
    };
    try {
      await GameService.updateGameLike(data);
      setIs_liked(true);
    } catch (error) {
      console.log('error while liking');

      setIs_liked(true);
    }
  }

  const onCloseAppRater = () => {
    gameDetailsById(gameId);
    setCanShowRateApp(false);
  }

  const onClickChallenge = () => {
    if (props.User) {
      setCanShowChallengeConfirmation(true);
    } else {
      if (props.showUserLogin) {
        props.showUserLogin();
      }
    }
  }
  return (
    <>
      <div className="shadow bg-white">

        <NavLink to={`${challengeId ? '/home-page' : `/${screenFrom}`} `}>
          <div className='d-flex px-3 py-2 position-sticky bg-black align-items-center' style={{
            top: 0,
            zIndex: 10,
            height: 60
          }}>
            <FontAwesomeIcon color='white' icon={faArrowLeft}></FontAwesomeIcon>
            <b className='moplay-lemon-clr ms-3 mb-0'>Back</b>
          </div>
        </NavLink>
      <h2 className='fw-bold title-reg ps-3 m-0 pb-3 bg-black'>{gameData?.name}</h2>
      </div>
      <div className='justify-content-center'
        style={{
          height: "250px",
          backgroundImage: `url(${GAME_URL}${gameData?.icon})`,
          backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center", backgroundColor: "black",
          position: "relative", display: "flex", alignItems: "center", flexDirection: "row"
        }}>
        {showPromoGameVideo && <video autoPlay loop playsInline className='item rounded shot-video w-100 mx-auto h-100' onClick={closePromoVideoPopup} >
          <source src={`${GAME_URL}${gameData?.video}`} type="video/mp4" />
        </video>}
        {gameData?.video && !showPromoGameVideo && <i onClick={showGamePromoVideo} className="bi bi-play-circle-fill me-2 position-absolute" style={{
          'width': '100px',
          'height': '100px',
          'fontSize': '50px',
          // 'background': 'white',
          'display': 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          // backgroundColor: '#eae6b4',
          cursor: 'pointer'
        }}></i>}
      </div>
      <div className='pb-50 container'>
        <div className='row mx-auto d-flex flex-column align-items-center'>
          <div className="col-12 mt-3">
            <div className="d-flex justify-content-between">
              <div className="border p-1 px-3 pointer" style={{ borderRadius: 20 }} onClick={() => setCanShowRateApp(true)}>
                <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                <small className="ms-2">{gameData?.avgRating || 0}</small>
              </div>
              <div className="border p-1 px-3 pointer" style={{ borderRadius: 20 }} onClick={likeGame}>
                <FontAwesomeIcon icon={faHeart} className={`${is_liked ? 'moplay-yellow-clr' : ''}`}></FontAwesomeIcon>
                <small className="ms-2">{gameData?.total_likes || 0}</small>
              </div>
            </div>
          </div>
          <div className='col-md-12 my-3 '>
            {/* <div className=""> */}
            {!challengeId && (
              <div className='d-flex mp-scroll-width-none' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                {isSinglePlayerAvailable && <button onClick={() => onOpenSinglePlayer(0)} className='btn btn-primary rounded-pill px-3 cursor-pointer mb-3'>Play
                  {/* (<img loading="lazy" src={Resources.COIN_PNG} alt="" width={15} /> 5) */}
                </button>}
                {isMultiplayerAvailable && <button onClick={onOpenMultiplayer} className='ms-3 btn btn-primary rounded-pill px-3 cursor-pointer mb-3'>Multiplayer
                  {/* (<img loading="lazy" src={Resources.COIN_PNG} alt="" width={15} /> 50) */}
                </button>}
                <button onClick={() => onClickChallenge()} className='ms-3 btn btn-primary rounded-pill px-3 cursor-pointer mb-3'>Challenge</button>
              </div>
            )}
            {/* </div> */}
            <div className='my-3 f-large' dangerouslySetInnerHTML={{ __html: gameData?.description }}>
              {/* {gameData?.description} */}
            </div>
            {gameData?.how_to_play === "undefined" ? <></> : gameData?.how_to_play && (
              <div className=''>
                <h5 className='fs-4 text-dark'>How To Play</h5>
                <div className='fw-normal f-14' dangerouslySetInnerHTML={{ __html: gameData?.how_to_play || "" }}>
                </div>
              </div>
            )}
            {challengeId && (
              <div className='my-3 p-3 shadow rounded'>
                <div className='fs-4 text-dark'>Challenge Info</div>
                <div className='fw-normal f-14'><span className='moplay-primary-color fw-bold fs-5'>{challengedInfoData?.challenger[0]?.name}</span> challenged {challengedInfoData?.message} bet amount of <span className='moplay-primary-color fw-bold fs-5'>{challengedInfoData?.bet_amount || "0"} coins.</span></div>
                {challengeId && (
                  <>
                    {!acceptChallengeErr && <div className='my-3 text-center'><button className='btn btn-primary rounded-pill px-5 cursor-pointer' onClick={onAcceptChallenge}>Accept Challenge</button></div>}
                    {acceptChallengeErr && <div className='my-3 text-center text-danger'>{acceptChallengeErr}</div>}
                  </>
                )}
              </div>
            )}
            {/* <div className='d-flex justify-content-center my-3'>
            {!challengeId && (<Link to={`/play/${gameId}`}><button className='btn btn-primary rounded-pill px-5 cursor-pointer'>Play</button></Link>)}
          </div> */}
          </div>
        </div>

        <AdBanner slotId={"5033386164"} />
        {leaderBoardData && leaderBoardData.length > 0 && <div className='container-fluid px-0' style={{ paddingBottom: 100 }}>
          {/* <div className="moplay-red-bg" style={{ height: 50 }}></div> */}
          <LeaderBoard leaderBoardData={leaderBoardData} type={gameData?.leaderboard_type} />
        </div>}

        {/* {showPromoGameVideo &&
          <>
            <div className="modal-backdrop fade show" ></div>
            <div className={`modal fade shadow  text-dark ${showPromoGameVideo ? "show " : "false"}`}
              style={{ display: showPromoGameVideo ? "block" : "none" }}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="text-dark">
                    <div className='d-flex justify-content-center flex-column'>
                      <video controls loop autoPlay className='item rounded shot-video w-100 mx-auto'>
                        <source src={`${GAME_URL}${gameData?.video}`} type="video/mp4" />
                      </video>
                      <button onClick={() => setShowPromoGameVideo(false)} className='btn text-primary'>Close Video</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        } */}
      </div >
      {canShowChallengeConfirmation &&
        <MrPopup heading='Challenge' content='Play the game, score as many points as possible, and challenge your friends to beat your high score to win coins!' onClose={() => setCanShowChallengeConfirmation(false)}>
          <button className='btn btn-outline-primary mt-3 mx-4' onClick={() => setCanShowChallengeConfirmation(false)}>Cancel</button>
          <button className="btn btn-primary mt-3 mx-4" onClick={() => onOpenSinglePlayer(1)}>Continue</button>
        </MrPopup>
      }
      {
        showCoinsPopup && <MrPopup content='You do not have enough coins to play the game. Please update your wallet' heading='Not enough coins?' onClose={() => setShowCoinsPopup(false)}>
          <NavLink to={'/shop'}><button className='btn btn-primary'>Shop</button></NavLink>
        </MrPopup>
      }
      {canShowRateApp && <RateApp onClose={onCloseAppRater} game={gameData}></RateApp>}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  wallet: state.UserStore?.user?.wallet,
  User: state.UserStore?.user,
  // participants: state.PlatformStore.multiplayerParticipants,
  // multiplayerState: state.PlatformStore.multiplayerState
});

const mapDispatchToProps = (dispatch: any) => ({
  clearMultiplayer: () => dispatch({ type: PlatformActionType.CLEAR_MULTIPLAYER }),
  updateWallet: (coins: number) => dispatch({ type: UserActionType.SPEND_COINS, data: coins }),
  updateScrolledGame: (gameId: string) => dispatch({ type: PlatformActionType.SCROLLED_GAME, data: gameId }),
  showUserLogin: () => dispatch({ data: PopupType.LOGIN, type: PlatformActionType.LOGIN }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GameInfo);

// export default GameInfo;