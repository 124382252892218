import React, { useEffect, useState } from 'react'
import { MoplayCache } from '../app/service/moplay-cache';
import { StatusGames } from '../app/service/statusgame.service';
import { NavLink, useNavigate } from 'react-router-dom';
import { PopupType } from '../app/redux/reducers/types';
import { connect } from 'react-redux';
import GameCard from '../app/components/game-card';
import AdBanner from '../components/ad-banner';

interface Props {
    userdata: any;
    notificationPopup?: (data: any) => void;
    showLoginPopup?: () => void;
}

const HomePage = (props: Props) => {
    const [gameData, setGameData] = useState<any[]>([]);
    const navigation = useNavigate();
    const [activeTab, setActiveTab] = useState('');
    const [availableCategories, setAvailableCategories] = useState<string[]>([]);
    const addSlots = [
        "5911353564",
        "9135486540",
        "8446280890",
        "3621329624",
        "9228879527",
        "5004669849",
        "8154373521"
    ]
    const handleTabClick = (tabId: string) => {
        const categoryElement = document.getElementById(`category_${tabId}`);
        console.log('category element ', categoryElement);

        if (categoryElement) {
            categoryElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
        }
        setActiveTab(tabId);
    };

    useEffect(() => {
        loadGames();
    }, [props.userdata])

    const loadGames = () => {
        StatusGames(props.userdata.user?._id || '').then((res: any) => {
            let items = [];
            let cats: string[] = [];
            MoplayCache.games.allGames = res;
            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                const catIndex = cats.findIndex(el => el === element.category);
                if (catIndex < 0) {
                    cats.push(element.category);
                }

                items.push(element);
            }
            setGameData(items);
            setAvailableCategories(cats);
            setActiveTab(cats[0]);
        });
    }

    return (
        <div className='text-center' style={{ paddingBottom: 200 }}>
            <div className='position-sticky d-lg-none top-0 bg-light' style={{ zIndex: 10 }}>
                <div className='py-2'>
                    <div className='d-flex overflow-x-auto mp-scroll-width-none'>
                        <ul className="nav nav-tabs border-0 d-flex overflow-auto mp-scroll-width-none flex-nowrap" id="myTab" role="tablist">
                            {availableCategories.map((tab) => (
                                <li className="nav-item d-flex" role="presentation" key={tab}>
                                    <button
                                        className={`nav-link text-dark text-nowrap f-14 border-0 px-3 px-md-3 ${activeTab === tab ? 'mp-home-page-active fw-bold' : ''}`}
                                        id={`${tab}-tab`} onClick={() => handleTabClick(tab)}
                                        data-bs-toggle="tab" type="button" role="tab"
                                        aria-controls={tab} aria-selected={activeTab === tab}
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {
                availableCategories.map((el, index) => <div className='container px-3 pt-2 py-4' id={`category_${el}`} key={`category_${el}`} style={{
                    // boxShadow: '2px 2px 5px 0px #e4e1e1'
                }}>
                    <AdBanner slotId={addSlots[index]} />
                    <div className='text-start'><strong className='text-dark text-start'>{el}</strong></div>
                    <div className='overflow-auto mp-scroll-width-none d-grid d-lg-none' style={{ gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                        {
                            gameData.filter(_game => _game.category === el).map((game, index) =>
                                <div key={game._id}>
                                    <GameCard game={game}></GameCard>
                                </div>
                            )
                        }
                    </div>

                    <div className='overflow-auto mp-scroll-width-none d-none d-lg-grid' style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        {
                            gameData.filter(_game => _game.category === el).map((game, index) =>
                                <div key={game._id}>
                                    <GameCard game={game}></GameCard>
                                </div>
                            )
                        }
                    </div>
                </div>)
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userdata: state.UserStore
});
const mapDispatchToProps = (dispatch: any) => ({
    showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' }),
})
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);