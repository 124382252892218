import { Game } from "../../model/dto/res/game";
import { MultiplayerBot } from "../../model/dto/res/multiplayer-bot";
import { User } from "../../model/dto/res/user";
import { MultiplayerGameData } from "../../model/dto/socket/multiplayer-game-data";
import { MultiplayerGameSummary } from "../../model/dto/socket/multiplayer-game-summary";
import { MultiplayerParticipant } from "../../model/dto/socket/multiplayer-participant";

export interface UserStore {
    user: User | null;
    popupType: PopupType;
    dailyBonusStatus: DailyBonusStatus;
}

export enum DailyBonusStatus {
    PENDING,
    COLLECTED,
    CLOSED
}
export interface PlatformStore {
    active_screen: PlatformScreen;
    popupType: PopupType;
    activeNotifications: string[];
    msgNotifications: string[];
    multiplayerState: MULITPLAYER_STATE;
    multiplayerParticipants: MultiplayerParticipant[];
    gameType: GamePlayType;
    multiplayerUserGameData: MultiplayerGameData;
    multiplayerOpponentGameData: MultiplayerGameData;
    multiplayerBotData: MultiplayerBot;
    activeGame: Game;
    scrolledGameId: string;
    typingNotifications: string[];
    msgDeliveryStatus: MsgDeliveryStatus;
    friends_status_refresh_id: string;
    initialShotIndex: number;
}

export interface MsgDeliveryStatus {
    id: string; 
    status: number;
}

export interface StorageState {
    UserStore: UserStore;
}

export enum GamePlayType {
    SINGLE_PLAYER,
    MULTIPLAYER,
    MULTIPLAYER_BOT
}
export enum PopupType {
    IDLE,
    LOGIN,
    SIGNUP,
    NOTIFICATION,
    MSG_NOTIFICATION
}

export enum PlatformScreen {
    NONE,
    HOME,
    SHOTS,
    IN_GAME,
    MATCH_SUMMARY
}

export enum PlatformActionType {
    NEW_NOTIFICATION = 'new_notification',
    LOGIN = 'login',
    POPUP = 'popup',
    MSG_NOTIFICATION = 'msg_notification',
    MSG_NOTIFICATION_CLEAR = 'msg_notification_clear',
    MULTIPLAYER_USER_JOINED = 'multiplayer_user_joined',
    MULTIPLAYER_REQUEST_GAMEPLAY = 'multiplayer_request_gameplay',
    MULTIPLAYER_WAITING = 'multiplayer_waiting',
    MULTIPLAYER_GAME_DATA = 'multiplayer_game_data',
    MULTIPLAYER_USER_DATA = 'multiplayer_user_game_data',
    ACTIVE_GAME = 'active_game',
    // MULTIPLAYER_GAME_SUMMARY = 'multiplayer_game_summary',
    CLEAR_MULTIPLAYER = 'clear_multiplayer',
    MULTIPLAYER_OPPONENT_EXIT = 'multiplayer_opponent_exit',
    MULTIPLAYER_GAME_END = 'multiplayer_game_end',
    UPDATE_ACTIVE_SCREEN = 'update_active_screen',
    MULTIPLAYER_BOT = 'multiplayer_bot',
    UPDATE_USER_PROFILE = 'update_user_profile',
    MSG_TYPING_NOTIFICATION = 'msg_typing_notification',
    MSG_TYPING_NOTIFICATION_DELETE = 'msg_typing_notification_delete',
    MSG_DELIVERY_STATUS = 'msg_delivery_status',
    FRIENDS_LOGIN_CHECKS = 'friends_login_checks',
    INITIAL_VIDEO_SHOT = 'initial_video_shot',
    SCROLLED_GAME = 'scorlled_game',
}

export enum UserActionType {
    UPDATE_USER_PROFILE = 'update_user_profile',
    LOGOUT='logout',
    SPEND_COINS = 'spend_coins',
    COLLECT_DAILY_BONUS = 'collect_daily_bonus'
}

export interface PlatformAction {
    type: PlatformActionType;
    data: any;
}

export enum MULITPLAYER_STATE {
    IDLE,
    WAITING_FOR_OPPONENT,
    CONNECTED,
    CHECK_READYNESS,
    OPPONENT_LEFT,
    END
}