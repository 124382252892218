import { useEffect, useState, useRef } from "react";

type AdBannerProps = {
    adFormat?: string;
    adHeight?: string;
    slotId?: string;
    onAdNotFilled?: () => void;
};

declare global {
    interface Window {
        adsbygoogle?: any[];
    }
}

const AdBanner: React.FC<AdBannerProps> = ({ adFormat, adHeight, slotId, onAdNotFilled }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const adRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!window.adsbygoogle) {
            const script = document.createElement("script");
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsLoaded(true);
                    observer.disconnect();
                }
            },
            { root: null, threshold: 0.1 }
        );

        if (adRef.current) observer.observe(adRef.current);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isLoaded && window.adsbygoogle && adRef.current) {
            const adStatus = adRef.current.getAttribute("data-adsbygoogle-status");
            if (adStatus !== "done") {
                try {
                    window.adsbygoogle.push({});
                } catch (err) {
                    console.error("AdSense Error:", err);
                    if (onAdNotFilled) onAdNotFilled();
                }
            }
        }
    }, [isLoaded, onAdNotFilled]);

    return (
        <div
            ref={adRef}
            style={{
                width: "100%",
                textAlign: "center",
                pointerEvents: 'none'
            }}
            className="mb-4 rounded text-light pb-4 custom-border"
        >
            <p className='text-center text-secondary fs-8'>ADVERTISEMENT</p>
            {isLoaded && (
                <ins
                    className="adsbygoogle"
                    style={{ display: "block", width: "100%", height: adHeight || "120px" }}
                    data-ad-client="ca-pub-3190660533180146"
                    data-ad-slot={slotId || "5406350906"}
                    data-ad-format={adFormat || "auto"}
                    data-full-width-responsive="true"
                ></ins>
            )}
        </div>
    );
};

export default AdBanner;
