import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { StatusGames } from '../app/service/statusgame.service';
import { Link, useNavigate } from 'react-router-dom';
import FlickScroller from '../components/flick-scroller';
import { connect } from 'react-redux';
import CoinDisplay from '../components/coin-display';
import { DailyBonusStatus, PlatformActionType, PlatformScreen, PopupType } from '../app/redux/reducers/types';
import { MoplayCache } from '../app/service/moplay-cache';
import DailyBonus from '../app/components/daily-bonus';
import { User } from '../app/model/dto/res/user';
import HeaderBar from '../components/header-bar';
interface Props {
    likeStatus?: any;
    showLoginPopup?: () => void;
    User?: User;
    dailyBonusStatus?: DailyBonusStatus;
    updateActiveScreen?: (screen: PlatformScreen) => void;
}
const Home = (props: Props) => {
    const [gameData, setGameData] = useState<any[]>([]);
    const [userLoginData, setUserLoginData] = useState<any>({});
    const [videoDuration, setVideoDuration] = useState<any>();
    // const [currentVideoTime, setCurrentVideoTime] = useState<any>();
    const [videoProgress, setVideoProgress] = useState<any>();
    const [canShowDailyRewards, setCanShowDailyRewards] = useState(false);
    const loginPopupRef: any = useRef();
    const navigation = useNavigate();

    useEffect(() => {
        if (props.updateActiveScreen) {
            props.updateActiveScreen(PlatformScreen.HOME);
        }
    
      return () => {
        props.updateActiveScreen(PlatformScreen.NONE);
      }
    }, [])
    

    useEffect(() => {
        const userData: any = localStorage?.getItem("userData");
        const parsedUserData = JSON.parse(userData);
        gameShow(parsedUserData?._id || "");

        if (props.showLoginPopup) {
            props.showLoginPopup();
        }
    }, []);

    useEffect(() => {
        if (props.User) {
            setCanShowDailyRewards(props.User.login_day > -1);
        }
    }, [props.User])

    function gameShow(data: any) {
        StatusGames(data || '').then((res: any) => {
            let items = [];
            MoplayCache.games.allGames = res;
            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                items.push(element);
                if (index > 0 && index % 2 === 0 && index < res.length - 1) {
                    items.push({ type: 'ad' });
                }
            }
            setGameData(items);
        });
    }

    const getVideoDuration = (duration: any) => {
        if (duration) {
            setVideoDuration(duration);
        }
    }

    const getVideoProgress = (data: any) => {
        if (data) {
            setVideoProgress(data);
        }
    }

    return (
        <>
            {/* <div className='position-fixed d-lg-none' style={{ zIndex: 10, right: 10, top: 10 }}>
                <CoinDisplay></CoinDisplay>
            </div> */}
            <div className={`container-fluid scroll-container px-0 position-relative`} ref={loginPopupRef}>
                <div className="d-flex justify-content-center h-100 bg-white">
                    <FlickScroller items={gameData}
                        videoDuration={(e: any) => getVideoDuration(e)} videoProgress={(e: any) => getVideoProgress(e)} />
                </div>
            </div >
            {canShowDailyRewards && props.dailyBonusStatus === DailyBonusStatus.PENDING && <DailyBonus onClose={() => setCanShowDailyRewards(false)}></DailyBonus>}
            {/* <img loading="lazy" src={ARROW_DOWN_IMG} onClick={goToNextVideo} alt="" className={`position-fixed navigation-down-arrow ${themeStatus ? "bg-light" : "bg-light-gray"}`} style={{ right: 50, bottom: 20 }} /> */}
        </>
    )
}

const mapStateToProps = (state: any) => ({
    User: state.UserStore?.user,
    dailyBonusStatus: state.UserStore?.dailyBonusStatus
});

const mapDispatchToProps = (dispatch: any) => ({
    showLoginPopup: () => dispatch({ data: PopupType.IDLE, type: 'popup' }),
    showRegistrationPopup: () => dispatch({ data: PopupType.SIGNUP, type: 'popup' }),
    updateActiveScreen: (data: PlatformScreen) => dispatch({ data, type: PlatformActionType.UPDATE_ACTIVE_SCREEN })
})

const connectedNavbar = connect(mapStateToProps, mapDispatchToProps)(Home);
export { connectedNavbar as Home }