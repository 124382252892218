import React, { useEffect, useState } from 'react'
import RewardImage from './../assets/images/reward-image.jpg';
import { GameService } from '../app/service/game.service';
import { LeaderboardRes } from '../app/model/dto/res/leaderboard.res';
import DisplayPicture from './ui/display-picture';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

interface Props {
    leaderBoardData?: any;
    gameId?: string;
    type?: number;
}

export const LeaderBoard = (props: Props) => {
    const [leaderboardScores, setLeaderboardScores] = useState<LeaderboardRes[]>([]);
    useEffect(() => {
        if (props.leaderBoardData) {
            setLeaderboardScores(props.leaderBoardData)
        }
    }, [props.leaderBoardData])

    useEffect(() => {
        if (props.gameId) {
            loadLeaderBoardDetails();
        }
    }, [props.gameId])

    const loadLeaderBoardDetails = async () => {
        try {
            const data = await GameService.getLoaderboard(props.gameId);
            setLeaderboardScores(data);
        } catch (error) {

        }
    }
    return (
        <div className='leaderboard'>
            <div className='mx-auto my-md-4 mb-5 text-center'>
                <div className="moplay-red-bg text-white py-5">
                    <h4 className='wix-title'>Leaderboard</h4>
                    {leaderboardScores.length < 1 && <p className='p-5'>
                        No one played the game. Score the maximum and be the top of the leaderboard
                    </p>}
                    {leaderboardScores.length > 2 && <div className="top-rankers">
                        <div>
                            <div className='ranker rank-2'>
                                <span className="dp">
                                    <DisplayPicture text_size={30} first_name={leaderboardScores[1].player_name[0] || ''} last_name='' width={60} height={60}></DisplayPicture>
                                </span>
                                <div className='player-info'>{leaderboardScores[1].player_name[0]}</div>
                                <h1 className='rank-number'>{leaderboardScores[1].score}</h1>
                                {/* <h1 className='rank-number'>2<sup>nd</sup></h1> */}
                            </div>
                        </div>
                        <div>
                            <div className='ranker rank-1'>
                                <span className="dp">
                                    <DisplayPicture text_size={30} first_name={leaderboardScores[0].player_name[0] || ''} last_name='' width={60} height={60}></DisplayPicture>
                                </span>
                                <div className='player-info'>{leaderboardScores[0].player_name[0]}</div>
                                <FontAwesomeIcon style={{bottom: 5}} icon={faTrophy} className={`position-absolute w-100 start-0 f-45 moplay-yellow-clr`}></FontAwesomeIcon>
                                <h1 className='rank-number'>{leaderboardScores[0].score}</h1>
                                {/* <h1 className='rank-number'>1<sup>st</sup></h1> */}
                            </div>

                        </div>
                        <div>
                            <div className='ranker rank-3'>
                                <span className="dp">
                                    <DisplayPicture text_size={30} first_name={leaderboardScores[2].player_name[0] || ''} last_name='' width={60} height={60}></DisplayPicture>
                                </span>
                                <div className='player-info'>{leaderboardScores[2].player_name[0]}</div>
                                <h1 className='rank-number'>{leaderboardScores[2].score}</h1>
                                {/* <h1 className='rank-number'>3<sup>rd</sup></h1> */}
                            </div>
                        </div>
                    </div>}

                </div>
                {leaderboardScores.length > 0 && <div className='px-0'>
                    <div className="mp-light-gray-bg py-2">
                        <div className="d-flex justify-content-around px-4">
                            <div style={{ width: '25%' }} className="mp-text-clr">Rank</div>
                            <div style={{ width: '50%' }} className="mp-text-clr">Player Name</div>
                            <div style={{ width: '25%' }} className="mp-text-clr">{props.type === 2 ? 'Level' : 'Score'}</div>
                            {/* <div className="">Wins</div> */}
                        </div>
                    </div>
                    {
                        leaderboardScores && leaderboardScores.map((el: any, index: number) =>
                            <div key={index} className="py-2 my-2 border-bottom" style={{}}>
                                <div className="d-flex justify-content-around px-4">
                                    <div className="mp-text-clr" style={{ width: '25%' }}>{index + 1}</div>
                                    <div className="mp-text-clr" style={{ width: '50%' }}>{el.player_name[0]}</div>
                                    <div className="mp-text-main-red" style={{ width: '25%' }}>{el.score}</div>
                                    {/* <div className="">2</div> */}
                                </div>
                            </div>
                        )
                    }
                </div>}
            </div>
        </div>
    )
}
