import React, { useEffect, useState } from 'react'
import HeaderBar from '../../components/header-bar'
import { ChallengeService } from '../service/challenge.service';
import { ChallengeInfo } from '../model/dto/res/challenge-info';
import { connect } from 'react-redux';
import { User } from '../model/dto/res/user';
import { Loading } from '../components/loading';
import { useNavigate, useNavigation } from 'react-router-dom';
import { BackBtnTitle } from '../components/back-btn-title'
import DisplayPicture from '../../components/ui/display-picture';
import appConfig, { ASSETS_URL } from '../../config/constant';
import { Resources } from '../utility/resources';

interface Props {
    userloginDetails?: User;
}
const Challenges = (props: Props) => {
    const [challenges, setChallenges] = useState<ChallengeInfo[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const navigator = useNavigate();
    useEffect(() => {
        loadChallenges();
    }, [])

    const loadChallenges = async () => {
        const data = await ChallengeService.getAllChallenges();
        setIsLoaded(true);
        setChallenges(data);
    }

    const onOpenNotification = (data: ChallengeInfo) => {
        navigator(`/challenge/${data._id}`);
    }
    return (
        <div className='challenges pb-5 mb-5'>
            <div className='container'>
                <BackBtnTitle text='Challenges' path='/'></BackBtnTitle>
                {!isLoaded && <Loading></Loading>}
                {
                    isLoaded && challenges.length<1&& <div className='d-flex align-items-center justify-content-center' style={{height: 100}}>You have no challenges</div>
                }
                {challenges?.map((data, index: number) => (
                    <div className='d-flex my-2 my-md-4 pointer p-3 shadow-bottom' key={index} onClick={() => onOpenNotification(data)}>
                        {data.game_info[0]?.icon && <DisplayPicture fitting='cover' width={50} height={50} profile_pic={`${ASSETS_URL}${data.game_info[0].icon}`} first_name='' last_name=''></DisplayPicture>}
                        <div className='ms-3'>

                            {data.challenger[0] && props.userloginDetails._id === data.challenger[0]._id && <p className='mb-1'>
                                You challenged {data.challenge_to[0] && <b>{data.challenge_to[0].name}</b>} to play {data.game_info[0] && <b>{data.game_info[0].name}</b>}
                            </p>}
                            {
                                data.challenge_to[0] && data.challenge_to[0]._id === props.userloginDetails._id && <p className='mb-1'>
                                    {data.challenger[0] && <b>{data.challenger[0].name}</b>} challenged you to play {data.game_info[0] && <b>{data.game_info[0].name}</b>}
                                </p>
                            }
                            <div className='d-flex align-items-center'>
                                Bet:
                                <b className='ms-1'> {data.bet_amount}</b>
                                <img loading="lazy" src={Resources.COIN_PNG} width={15} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user
});

export default connect(mapStateToProps)(Challenges);