import React, { useEffect } from 'react'
import appConfig, { GAME_URL } from '../config/constant';
import NODATA_ICON from "../assets/images/icons/noresultsFound.png";
import STATIC_GAME_ICON from "./../assets/images/static-game.jpg";
import { useNavigate } from 'react-router-dom';
import GameCard from '../app/components/game-card';

interface Props {
    items?: any;
    likedGameNavigation?: any;
    homePageGamesList?: any;
    likedGamesContainer?: any;
    publisherPage?: any;
    // promoVideos?: any;
    publisherGame?: any;
}

export const GamesList = (props: Props) => {
    const navigation = useNavigate();

    const onClikedOnGamesIcon = (data: any) => {
        if (props?.likedGameNavigation) {
            navigation(`/detail/${data?._id}`)
        }
    }
    return (
        <div className={`games-list mb-3`}>
            {props?.items?.length > 0 && props?.items?.map((item: any, index: number) => {
                return (
                        <GameCard game={item}></GameCard>
                )
            })}
            {/* {props?.promoVideos && (
                <>
                    {props?.items?.length > 0 && props?.items?.map((el: any, index: number) => {
                        return (
                            <div className='col-md-4 col-lg-3 mx-2' key={index}>
                                <div className='card rounded-4 my-2 border-0 bg-transparent mp-cursor-pointer'>
                                    <video controls playsInline controlsList="nofullscreen" loop autoPlay className='mp-game-list-promo-video' >
                                        {el?.video && <source src={GAME_URL + el?.video} type="video/mp4" />}
                                    </video>
                                    <div className='my-1 mx-2'>{el?.name}</div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )} */}
            {props?.items?.length === 0 && (
                <div className="col-12 mt-5 mx-auto algin-items-center text-center text-white">
                    {!props?.publisherPage && (<> You don't have any liked games. Go to home to like the games.</>)}
                    {props?.publisherPage && (<> You don't have any games.</>)}
                </div>
            )}
            {/*{props?.promoVideos && (<>
                 {props?.items?.length === 0 && (
                    <div className="col-12 mt-5 mx-auto algin-items-center text-center text-dark">
                        {!props?.publisherPage && (<> You don't have promo videos</>)}
                        {props?.publisherPage && (<> You don't have promo videos.</>)}
                    </div>
                )}
            </>)} */}
        </div>
    )
}

